import React from "react";

import seaTransport from "../img/sea-transport.jpg";

import bladeTransport2 from "../img/blade-transport-2.jpg";

import bladeWobbling from "../img/wobble/wobble.jpg";

import instrCamp from "../img/rotor_animation_details_removed.gif";

import idphen from "../img/bill-next-to-turbines.jpg";

import { PageContent } from "../components/PageContent";
import { PageHeader } from "../components/PageHeader";

const Experience: React.FC = () => (
  <section>
    <PageHeader
      title="Experience"
      content="Examples of projects that we have undertaken"
    />
    <PageContent
      imageWidth={4}
      content={[
        {
          name: "Instrumentation campaign on a prototype turbine:",
          text: "Working closely with a major OEM, Eleven-I I used their sensor hardware to complement the standard validation instrumentation package. During this ongoing project we worked closely with the validation and systems team to provide new insights and identify previously undetected issues. During this project we provided automated analysis tool and manual analysis when required",
          image: (
            <img
              src={instrCamp}
              className="card-img"
              alt="Instrumentation campaign"
            />
          ),
        },
        {
          name: "Identification/monitoring of specific phenomena:",
          text: "Building on the work carried out on the prototype turbine campaign we provided a number of systems to monitor and identify certain phenomena, as well as providing the  other outputs of system. This project allowed us to prove the retro-fit capabilities of our system and to build on our comparative data analysis tools.",
          image: (
            <img
              src={idphen}
              className="card-img"
              alt="Identification/monitoring of specific phenomena"
            />
          ),
        },
        {
          name: "Blade Transport - Sea",
          text: "We monitored a prototype 79m blade during 6 week sea transit. We provided basic condition monitoring based on strain gauges.",
          image: (
            <img
              src={seaTransport}
              className="card-img"
              alt="Blade transportation"
            />
          ),
        },
        {
          name: "Blade Transport - Land",
          text: "We monitored a prototype 56m blade during a 10 day road transit. We provided condition monitoring with tracking, alerts and streaming data. Our system had a robust power supply, vehicle charged with a solar/battery backup.",
          image: (
            <img
              src={bladeTransport2}
              className="card-img"
              alt="Blade transportation"
            />
          ),
        },
        {
          name: "Prototype Blade Analysis",
          text: "We tested a prototype 56m blade before it was fitted to the turbine. Our experiments verified that structural characteristics of the blade met the specification.",
          image: (
            <img
              src={bladeWobbling}
              className="card-img"
              alt="Blade transportation"
            />
          ),
        },
      ]}
    />
  </section>
);

export { Experience };
